import React from 'react';
import Layout from "../components/layout";
import SEO from "../components/seo";
import Banner from "../components/react/banner";
import MakingProcess from "../components/react/making-process";
import BuildTogether from "../components/react/build-together";
import CustomApps from "../components/react/custom-apps";
import BuildTogether2 from "../components/react/build-together2";
import Testimonial from "../components/home/testimonial";

const IOSPage = (props) => (
    <Layout {...props}>
        <SEO title="IOS Development"/>
        <div class="col-12 wrapper main-bggrey p-0">
        <div class="col-12 inner-page-content home-inner px-0"> 
                  <Banner/> 
            </div>
        </div>
        <MakingProcess/>
        <BuildTogether/>
        <CustomApps/>
        <BuildTogether2/>
        <Testimonial/>
    </Layout>
)

export default IOSPage;

